enifed('@ember/polyfills/index', ['exports', '@ember/polyfills/lib/assign', '@ember/polyfills/lib/merge'], function (exports, _assign, _merge) {
  'use strict';

  Object.defineProperty(exports, 'assign', {
    enumerable: true,
    get: function () {
      return _assign.default;
    }
  });
  Object.defineProperty(exports, 'assignPolyfill', {
    enumerable: true,
    get: function () {
      return _assign.assign;
    }
  });
  Object.defineProperty(exports, 'merge', {
    enumerable: true,
    get: function () {
      return _merge.default;
    }
  });
});