enifed('ember-testing/lib/test/run', ['exports', '@ember/runloop'], function (exports, _runloop) {
  'use strict';

  exports.default = run;
  function run(fn) {
    if (!(0, _runloop.getCurrentRunLoop)()) {
      return (0, _runloop.run)(fn);
    } else {
      return fn();
    }
  }
});