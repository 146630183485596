define('ember-file-upload/system/file-reader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var _EmberRSVP$defer = Ember.RSVP.defer('ember-file-upload: ' + options.label),
        resolve = _EmberRSVP$defer.resolve,
        reject = _EmberRSVP$defer.reject,
        promise = _EmberRSVP$defer.promise;

    var reader = new FileReader();

    reader.onload = resolve;
    reader.onerror = reject;

    var aborted = void 0;
    var cancel = function cancel() {
      if (aborted == null) {
        aborted = Ember.RSVP.defer('ember-file-upload: Abort ' + options.label);
        reader.abort();
      }
      return aborted.promise;
    };
    reader.onabort = function () {
      aborted.resolve();
    };

    /**
      Reads the file and returns a promise that will
      return the blob as ArrayBuffer.
       @method readAsArrayBuffer
      @return {Promise} A promise that will return the file as an ArrayBuffer
     */

    /**
      Reads the file and returns a promise that will
      return the blob as data URL.
       This is useful for reading images to display
      as a preview in the browser.
       @method readAsDataURL
      @return {Promise} A promise that will return the file as a data URL
     */

    /**
      Reads the file and returns a promise that will
      return the blob as binary string.
       This is useful for reading images or files that
      are not plain text.
       @method readAsBinaryString
      @return {Promise} A promise that will return the file as a binary string
     */

    /**
      Reads the file and returns a promise that will
      return the blob as text.
       This is useful for reading plain text files.
       @method readAsText
      @return {Promise} A promise that will return the file as text
     */
    ['readAsArrayBuffer', 'readAsDataURL', 'readAsBinaryString', 'readAsText'].forEach(function (methodName) {
      _this[methodName] = function (blob) {
        reader[methodName](blob);
        var p = promise.then(function () {
          return reader.result;
        }, function () {
          return Ember.RSVP.reject(reader.error);
        }, 'ember-file-upload: Unpack ' + options.label);
        p.cancel = cancel;
        return p;
      };
    });
  };
});