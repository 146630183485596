enifed('ember-routing/lib/location/hash_location', ['exports', '@ember/runloop', 'ember-metal', 'ember-runtime', 'ember-routing/lib/location/api'], function (exports, _runloop, _emberMetal, _emberRuntime, _api) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    implementation: 'hash',

    init: function () {
      (0, _emberMetal.set)(this, 'location', (0, _emberMetal.get)(this, '_location') || window.location);

      this._hashchangeHandler = undefined;
    },


    /**
      @private
       Returns normalized location.hash
       @since 1.5.1
      @method getHash
    */
    getHash: _api.default._getHash,

    /**
      Returns the normalized URL, constructed from `location.hash`.
       e.g. `#/foo` => `/foo` as well as `#/foo#bar` => `/foo#bar`.
       By convention, hashed paths must begin with a forward slash, otherwise they
      are not treated as a path so we can distinguish intent.
       @private
      @method getURL
    */
    getURL: function () {
      var originalPath = this.getHash().substr(1);
      var outPath = originalPath;

      if (outPath[0] !== '/') {
        outPath = '/';

        // Only add the # if the path isn't empty.
        // We do NOT want `/#` since the ampersand
        // is only included (conventionally) when
        // the location.hash has a value
        if (originalPath) {
          outPath += '#' + originalPath;
        }
      }

      return outPath;
    },


    /**
      Set the `location.hash` and remembers what was set. This prevents
      `onUpdateURL` callbacks from triggering when the hash was set by
      `HashLocation`.
       @private
      @method setURL
      @param path {String}
    */
    setURL: function (path) {
      (0, _emberMetal.get)(this, 'location').hash = path;
      (0, _emberMetal.set)(this, 'lastSetURL', path);
    },


    /**
      Uses location.replace to update the url without a page reload
      or history modification.
       @private
      @method replaceURL
      @param path {String}
    */
    replaceURL: function (path) {
      (0, _emberMetal.get)(this, 'location').replace('#' + path);
      (0, _emberMetal.set)(this, 'lastSetURL', path);
    },


    /**
      Register a callback to be invoked when the hash changes. These
      callbacks will execute when the user presses the back or forward
      button, but not after `setURL` is invoked.
       @private
      @method onUpdateURL
      @param callback {Function}
    */
    onUpdateURL: function (callback) {
      this._removeEventListener();

      this._hashchangeHandler = (0, _runloop.bind)(this, function () {
        var path = this.getURL();
        if ((0, _emberMetal.get)(this, 'lastSetURL') === path) {
          return;
        }

        (0, _emberMetal.set)(this, 'lastSetURL', null);

        callback(path);
      });

      window.addEventListener('hashchange', this._hashchangeHandler);
    },


    /**
      Given a URL, formats it to be placed into the page as part
      of an element's `href` attribute.
       This is used, for example, when using the {{action}} helper
      to generate a URL based on an event.
       @private
      @method formatURL
      @param url {String}
    */
    formatURL: function (url) {
      return '#' + url;
    },


    /**
      Cleans up the HashLocation event listener.
       @private
      @method willDestroy
    */
    willDestroy: function () {
      this._removeEventListener();
    },
    _removeEventListener: function () {
      if (this._hashchangeHandler) {
        window.removeEventListener('hashchange', this._hashchangeHandler);
      }
    }
  });
});