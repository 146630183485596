enifed('ember-console/index', ['exports', '@ember/debug', '@ember/deprecated-features'], function (exports, _debug, _deprecatedFeatures) {
  'use strict';

  // Deliver message that the function is deprecated

  var DEPRECATION_MESSAGE = 'Use of Ember.Logger is deprecated. Please use `console` for logging.';
  var DEPRECATION_ID = 'ember-console.deprecate-logger';
  var DEPRECATION_URL = 'https://emberjs.com/deprecations/v3.x#toc_use-console-rather-than-ember-logger';
  /**
     @module ember
  */

  /**
    Inside Ember-Metal, simply uses the methods from `imports.console`.
    Override this to provide more robust logging functionality.
  
    @class Logger
    @deprecated Use 'console' instead
  
    @namespace Ember
    @public
  */
  var DEPRECATED_LOGGER = void 0;

  if (_deprecatedFeatures.LOGGER) {
    DEPRECATED_LOGGER = {
      log: function () {
        var _console;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        return (_console = console).log.apply(_console, arguments); // eslint-disable-line no-console
      },
      warn: function () {
        var _console2;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        return (_console2 = console).warn.apply(_console2, arguments); // eslint-disable-line no-console
      },
      error: function () {
        var _console3;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        return (_console3 = console).error.apply(_console3, arguments); // eslint-disable-line no-console
      },
      info: function () {
        var _console4;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        return (_console4 = console).info.apply(_console4, arguments); // eslint-disable-line no-console
      },
      debug: function () {
        var _console6;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        /* eslint-disable no-console */
        if (console.debug) {
          var _console5;

          return (_console5 = console).debug.apply(_console5, arguments);
        }
        return (_console6 = console).info.apply(_console6, arguments);
        /* eslint-enable no-console */
      },
      assert: function () {
        var _console7;

        (true && !(false) && (0, _debug.deprecate)(DEPRECATION_MESSAGE, false, {
          id: DEPRECATION_ID,
          until: '4.0.0',
          url: DEPRECATION_URL
        }));

        return (_console7 = console).assert.apply(_console7, arguments); // eslint-disable-line no-console
      }
    };
  }

  exports.default = DEPRECATED_LOGGER;
});