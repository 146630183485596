enifed("ember-views/lib/system/action_manager", ["exports"], function (exports) {
  "use strict";

  exports.default = ActionManager;
  /**
  @module ember
  */

  function ActionManager() {}

  /**
    Global action id hash.
  
    @private
    @property registeredActions
    @type Object
  */
  ActionManager.registeredActions = {};
});