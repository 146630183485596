enifed('ember-views/lib/views/states/destroying', ['exports', '@ember/polyfills', '@ember/error', 'ember-views/lib/views/states/default'], function (exports, _polyfills, _error, _default2) {
  'use strict';

  var destroying = Object.create(_default2.default);

  (0, _polyfills.assign)(destroying, {
    appendChild: function () {
      throw new _error.default("You can't call appendChild on a view being destroyed");
    },
    rerender: function () {
      throw new _error.default("You can't call rerender on a view being destroyed");
    }
  });

  exports.default = destroying;
});