enifed('@ember/application/globals-resolver', ['exports', 'ember-babel', 'ember-utils', 'ember-metal', '@ember/debug', '@ember/string', 'ember-runtime', '@ember/application/lib/validate-type', 'ember-glimmer'], function (exports, _emberBabel, _emberUtils, _emberMetal, _debug, _string, _emberRuntime, _validateType, _emberGlimmer) {
  'use strict';

  var DefaultResolver = function (_EmberObject) {
    (0, _emberBabel.inherits)(DefaultResolver, _EmberObject);

    function DefaultResolver() {
      (0, _emberBabel.classCallCheck)(this, DefaultResolver);
      return (0, _emberBabel.possibleConstructorReturn)(this, _EmberObject.apply(this, arguments));
    }

    DefaultResolver.create = function create(props) {
      // DO NOT REMOVE even though this doesn't do anything
      // This is required for a FireFox 60+ JIT bug with our tests.
      // without it, create(props) in our tests would lose props on a deopt.
      return _EmberObject.create.call(this, props);
    };

    DefaultResolver.prototype.init = function init() {
      this._parseNameCache = (0, _emberUtils.dictionary)(null);
    };

    DefaultResolver.prototype.normalize = function normalize(fullName) {
      var _fullName$split = fullName.split(':'),
          type = _fullName$split[0],
          name = _fullName$split[1];

      (true && !(fullName.split(':').length === 2) && (0, _debug.assert)('Tried to normalize a container name without a colon (:) in it. ' + 'You probably tried to lookup a name that did not contain a type, ' + 'a colon, and a name. A proper lookup name would be `view:post`.', fullName.split(':').length === 2));


      if (type !== 'template') {
        var result = name.replace(/(\.|_|-)./g, function (m) {
          return m.charAt(1).toUpperCase();
        });

        return type + ':' + result;
      } else {
        return fullName;
      }
    };

    DefaultResolver.prototype.resolve = function resolve(fullName) {
      var parsedName = this.parseName(fullName);
      var resolveMethodName = parsedName.resolveMethodName;
      var resolved = void 0;

      if (this[resolveMethodName]) {
        resolved = this[resolveMethodName](parsedName);
      }

      resolved = resolved || this.resolveOther(parsedName);

      if (true) {
        if (parsedName.root && parsedName.root.LOG_RESOLVER) {
          this._logLookup(resolved, parsedName);
        }
      }

      if (resolved) {
        (0, _validateType.default)(resolved, parsedName);
      }

      return resolved;
    };

    DefaultResolver.prototype.parseName = function parseName(fullName) {
      return this._parseNameCache[fullName] || (this._parseNameCache[fullName] = this._parseName(fullName));
    };

    DefaultResolver.prototype._parseName = function _parseName(fullName) {
      var _fullName$split2 = fullName.split(':'),
          type = _fullName$split2[0],
          fullNameWithoutType = _fullName$split2[1];

      var name = fullNameWithoutType;
      var namespace = (0, _emberMetal.get)(this, 'namespace');
      var root = namespace;
      var lastSlashIndex = name.lastIndexOf('/');
      var dirname = lastSlashIndex !== -1 ? name.slice(0, lastSlashIndex) : null;

      if (type !== 'template' && lastSlashIndex !== -1) {
        var parts = name.split('/');
        name = parts[parts.length - 1];
        var namespaceName = (0, _string.capitalize)(parts.slice(0, -1).join('.'));
        root = (0, _emberMetal.findNamespace)(namespaceName);

        (true && !(root) && (0, _debug.assert)('You are looking for a ' + name + ' ' + type + ' in the ' + namespaceName + ' namespace, but the namespace could not be found', root));
      }

      var resolveMethodName = fullNameWithoutType === 'main' ? 'Main' : (0, _string.classify)(type);

      if (!(name && type)) {
        throw new TypeError('Invalid fullName: `' + fullName + '`, must be of the form `type:name` ');
      }

      return {
        fullName: fullName,
        type: type,
        fullNameWithoutType: fullNameWithoutType,
        dirname: dirname,
        name: name,
        root: root,
        resolveMethodName: 'resolve' + resolveMethodName
      };
    };

    DefaultResolver.prototype.lookupDescription = function lookupDescription(fullName) {
      var parsedName = this.parseName(fullName);
      var description = void 0;

      if (parsedName.type === 'template') {
        return 'template at ' + parsedName.fullNameWithoutType.replace(/\./g, '/');
      }

      description = parsedName.root + '.' + (0, _string.classify)(parsedName.name).replace(/\./g, '');

      if (parsedName.type !== 'model') {
        description += (0, _string.classify)(parsedName.type);
      }

      return description;
    };

    DefaultResolver.prototype.makeToString = function makeToString(factory) {
      return factory.toString();
    };

    DefaultResolver.prototype.useRouterNaming = function useRouterNaming(parsedName) {
      if (parsedName.name === 'basic') {
        parsedName.name = '';
      } else {
        parsedName.name = parsedName.name.replace(/\./g, '_');
      }
    };

    DefaultResolver.prototype.resolveTemplate = function resolveTemplate(parsedName) {
      var templateName = parsedName.fullNameWithoutType.replace(/\./g, '/');

      return (0, _emberGlimmer.getTemplate)(templateName) || (0, _emberGlimmer.getTemplate)((0, _string.decamelize)(templateName));
    };

    DefaultResolver.prototype.resolveView = function resolveView(parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    };

    DefaultResolver.prototype.resolveController = function resolveController(parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    };

    DefaultResolver.prototype.resolveRoute = function resolveRoute(parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    };

    DefaultResolver.prototype.resolveModel = function resolveModel(parsedName) {
      var className = (0, _string.classify)(parsedName.name);
      var factory = (0, _emberMetal.get)(parsedName.root, className);

      return factory;
    };

    DefaultResolver.prototype.resolveHelper = function resolveHelper(parsedName) {
      return this.resolveOther(parsedName);
    };

    DefaultResolver.prototype.resolveOther = function resolveOther(parsedName) {
      var className = (0, _string.classify)(parsedName.name) + (0, _string.classify)(parsedName.type);
      var factory = (0, _emberMetal.get)(parsedName.root, className);
      return factory;
    };

    DefaultResolver.prototype.resolveMain = function resolveMain(parsedName) {
      var className = (0, _string.classify)(parsedName.type);
      return (0, _emberMetal.get)(parsedName.root, className);
    };

    DefaultResolver.prototype.knownForType = function knownForType(type) {
      var namespace = (0, _emberMetal.get)(this, 'namespace');
      var suffix = (0, _string.classify)(type);
      var typeRegexp = new RegExp(suffix + '$');

      var known = (0, _emberUtils.dictionary)(null);
      var knownKeys = Object.keys(namespace);
      for (var index = 0; index < knownKeys.length; index++) {
        var name = knownKeys[index];

        if (typeRegexp.test(name)) {
          var containerName = this.translateToContainerFullname(type, name);

          known[containerName] = true;
        }
      }

      return known;
    };

    DefaultResolver.prototype.translateToContainerFullname = function translateToContainerFullname(type, name) {
      var suffix = (0, _string.classify)(type);
      var namePrefix = name.slice(0, suffix.length * -1);
      var dasherizedName = (0, _string.dasherize)(namePrefix);

      return type + ':' + dasherizedName;
    };

    return DefaultResolver;
  }(_emberRuntime.Object);

  exports.default = DefaultResolver;


  if (true) {
    /**
        @method _logLookup
        @param {Boolean} found
        @param {Object} parsedName
        @private
      */
    DefaultResolver.prototype._logLookup = function (found, parsedName) {
      var symbol = found ? '[✓]' : '[ ]';

      var padding = void 0;
      if (parsedName.fullName.length > 60) {
        padding = '.';
      } else {
        padding = new Array(60 - parsedName.fullName.length).join('.');
      }

      (0, _debug.info)(symbol, parsedName.fullName, padding, this.lookupDescription(parsedName.fullName));
    };
  }
});