enifed('ember-runtime/lib/system/object_proxy', ['exports', 'ember-babel', 'ember-runtime/lib/system/object', 'ember-runtime/lib/mixins/-proxy'], function (exports, _emberBabel, _object, _proxy) {
  'use strict';

  var ObjectProxy = function (_FrameworkObject) {
    (0, _emberBabel.inherits)(ObjectProxy, _FrameworkObject);

    function ObjectProxy() {
      (0, _emberBabel.classCallCheck)(this, ObjectProxy);
      return (0, _emberBabel.possibleConstructorReturn)(this, _FrameworkObject.apply(this, arguments));
    }

    return ObjectProxy;
  }(_object.default);

  exports.default = ObjectProxy;

  ObjectProxy.PrototypeMixin.reopen(_proxy.default);
});