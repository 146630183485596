define('ember-concurrency/-task-group', ['exports', 'ember-concurrency/utils', 'ember-concurrency/-task-state-mixin', 'ember-concurrency/-property-modifiers-mixin'], function (exports, _utils, _taskStateMixin, _propertyModifiersMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TaskGroupProperty = exports.TaskGroup = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var TaskGroup = exports.TaskGroup = Ember.Object.extend(_taskStateMixin.default, {
    isTaskGroup: true,

    toString: function toString() {
      return '<TaskGroup:' + this._propertyName + '>';
    },


    _numRunningOrNumQueued: Ember.computed.or('numRunning', 'numQueued'),
    isRunning: Ember.computed.bool('_numRunningOrNumQueued'),
    isQueued: false
  });

  var TaskGroupProperty = exports.TaskGroupProperty = function (_ComputedProperty2) {
    _inherits(TaskGroupProperty, _ComputedProperty2);

    function TaskGroupProperty(taskFn) {
      _classCallCheck(this, TaskGroupProperty);

      var tp = void 0;

      var _this = _possibleConstructorReturn(this, (TaskGroupProperty.__proto__ || Object.getPrototypeOf(TaskGroupProperty)).call(this, function (_propertyName) {
        return TaskGroup.create({
          fn: taskFn,
          context: this,
          _origin: this,
          _taskGroupPath: tp._taskGroupPath,
          _scheduler: (0, _propertyModifiersMixin.resolveScheduler)(tp, this, TaskGroup),
          _propertyName: _propertyName
        });
      }));

      tp = _this;
      return _this;
    }

    return TaskGroupProperty;
  }(_utils._ComputedProperty);

  (0, _utils.objectAssign)(TaskGroupProperty.prototype, _propertyModifiersMixin.propertyModifiers);
});