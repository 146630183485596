enifed("ember-views/lib/views/states/default", ["exports", "@ember/error"], function (exports, _error) {
  "use strict";

  exports.default = {
    // appendChild is only legal while rendering the buffer.
    appendChild: function () {
      throw new _error.default("You can't use appendChild outside of the rendering process");
    },


    // Handle events from `Ember.EventDispatcher`
    handleEvent: function () {
      return true; // continue event propagation
    },
    rerender: function () {},
    destroy: function () {}
  };
});