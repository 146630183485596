define("ember-responsive/null-match-media", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    return {
      matches: false
    };
  };
});