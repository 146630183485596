define('ember-file-upload/system/parse-xml', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = parseXML;


  // This method is taken from
  // https://github.com/jquery/jquery/blob/2d4f53416e5f74fa98e0c1d66b6f3c285a12f0ce/src/ajax/parseXML.js

  function parseXML(data) {
    var xml = void 0;
    if (!data || typeof data !== 'string') {
      return null;
    }

    // Support: IE 9 - 11 only
    // IE throws on parseFromString with invalid input.
    try {
      xml = new window.DOMParser().parseFromString(data, 'text/xml');
    } catch (e) {
      xml = undefined;
    }

    if (true) {
      if (!xml || xml.getElementsByTagName('parsererror').length) {
        (true && !(false) && Ember.assert('Invalid XML: ' + data));
      }
    }
    return xml;
  }
});