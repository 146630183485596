define("ember-i18n/index", ["exports", "ember-i18n/utils/i18n/compile-template", "ember-i18n/services/i18n", "ember-i18n/utils/macro"], function (exports, _compileTemplate, _i18n, _macro) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.translationMacro = exports.Service = exports.compileTemplate = undefined;
  exports.compileTemplate = _compileTemplate.default;
  exports.Service = _i18n.default;
  exports.translationMacro = _macro.default;
});