enifed('@glimmer/low-level', ['exports', 'ember-babel'], function (exports, _emberBabel) {
    'use strict';

    exports.Stack = exports.Storage = undefined;

    var Storage = function () {
        function Storage() {
            (0, _emberBabel.classCallCheck)(this, Storage);

            this.array = [];
            this.next = 0;
        }

        Storage.prototype.add = function add(element) {
            var slot = this.next,
                array = this.array;

            if (slot === array.length) {
                this.next++;
            } else {
                var prev = array[slot];
                this.next = prev;
            }
            this.array[slot] = element;
            return slot;
        };

        Storage.prototype.deref = function deref(pointer) {
            return this.array[pointer];
        };

        Storage.prototype.drop = function drop(pointer) {
            this.array[pointer] = this.next;
            this.next = pointer;
        };

        return Storage;
    }();

    var Stack = function () {
        function Stack() {
            var vec = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
            (0, _emberBabel.classCallCheck)(this, Stack);

            this.vec = vec;
        }

        Stack.prototype.clone = function clone() {
            return new Stack(this.vec.slice());
        };

        Stack.prototype.sliceFrom = function sliceFrom(start) {
            return new Stack(this.vec.slice(start));
        };

        Stack.prototype.slice = function slice(start, end) {
            return new Stack(this.vec.slice(start, end));
        };

        Stack.prototype.copy = function copy(from, to) {
            this.vec[to] = this.vec[from];
        };

        Stack.prototype.writeRaw = function writeRaw(pos, value) {
            // TODO: Grow?
            this.vec[pos] = value;
        };

        Stack.prototype.writeSmi = function writeSmi(pos, value) {
            this.vec[pos] = encodeSmi(value);
        };

        Stack.prototype.getRaw = function getRaw(pos) {
            return this.vec[pos];
        };

        Stack.prototype.getSmi = function getSmi(pos) {
            return decodeSmi(this.vec[pos]);
        };

        Stack.prototype.reset = function reset() {
            this.vec.length = 0;
        };

        Stack.prototype.len = function len() {
            return this.vec.length;
        };

        return Stack;
    }();

    function decodeSmi(smi) {
        switch (smi & 7) {
            case 0 /* NUMBER */:
                return smi >> 3;
            case 4 /* NEGATIVE */:
                return -(smi >> 3);
            default:
                throw new Error('unreachable');
        }
    }
    function encodeSmi(primitive) {
        if (primitive < 0) {
            return Math.abs(primitive) << 3 | 4 /* NEGATIVE */;
        } else {
            return primitive << 3 | 0 /* NUMBER */;
        }
    }

    exports.Storage = Storage;
    exports.Stack = Stack;
});