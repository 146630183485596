enifed('ember-views/lib/mixins/child_views_support', ['exports', 'ember-metal', 'ember-views/lib/system/utils'], function (exports, _emberMetal, _utils) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    /**
      Array of child views. You should never edit this array directly.
       @property childViews
      @type Array
      @default []
      @private
    */
    childViews: (0, _emberMetal.descriptor)({
      configurable: false,
      enumerable: false,
      get: function () {
        return (0, _utils.getChildViews)(this);
      }
    }),

    appendChild: function (view) {
      (0, _utils.addChildView)(this, view);
    }
  });
});