enifed('ember-runtime/lib/system/core_object', ['exports', 'ember-babel', 'container', '@ember/deprecated-features', '@ember/polyfills', 'ember-utils', '@ember/runloop', 'ember-meta', 'ember-metal', 'ember-runtime/lib/mixins/action_handler', '@ember/debug', 'ember-environment'], function (exports, _emberBabel, _container, _deprecatedFeatures, _polyfills, _emberUtils, _runloop, _emberMeta, _emberMetal, _action_handler, _debug, _emberEnvironment) {
  'use strict';

  /**
    @module @ember/object
  */

  var _reopen = _emberMetal.Mixin.prototype.reopen;

  var wasApplied = new _emberUtils.WeakSet();

  var factoryMap = new WeakMap();

  var prototypeMixinMap = new WeakMap();

  /**
    @class CoreObject
    @public
  */

  var CoreObject = function () {
    CoreObject._initFactory = function _initFactory(factory) {
      factoryMap.set(this, factory);
    };

    function CoreObject(properties) {
      var _self;

      (0, _emberBabel.classCallCheck)(this, CoreObject);

      // pluck off factory
      var initFactory = factoryMap.get(this.constructor);
      if (initFactory !== undefined) {
        factoryMap.delete(this.constructor);
        _container.FACTORY_FOR.set(this, initFactory);
      }

      // prepare prototype...
      this.constructor.proto();

      var self = this;

      var beforeInitCalled = void 0; // only used in debug builds to enable the proxy trap

      // using DEBUG here to avoid the extraneous variable when not needed
      if (true) {
        beforeInitCalled = true;
      }

      if (true && _emberUtils.HAS_NATIVE_PROXY && typeof self.unknownProperty === 'function') {
        var messageFor = function (obj, property) {
          return 'You attempted to access the `' + String(property) + '` property (of ' + obj + ').\n' + 'Since Ember 3.1, this is usually fine as you no longer need to use `.get()`\n' + 'to access computed properties. However, in this case, the object in question\n' + 'is a special kind of Ember object (a proxy). Therefore, it is still necessary\n' + ('to use `.get(\'' + String(property) + '\')` in this case.\n\n') + 'If you encountered this error because of third-party code that you don\'t control,\n' + 'there is more information at https://github.com/emberjs/ember.js/issues/16148, and\n' + 'you can help us improve this error message by telling us more about what happened in\n' + 'this situation.';
        };

        /* globals Proxy Reflect */
        self = new Proxy(this, {
          get: function (target, property, receiver) {
            if (property === _emberMetal.PROXY_CONTENT) {
              return target;
            } else if (beforeInitCalled || typeof property === 'symbol' || (0, _emberUtils.isInternalSymbol)(property) || property === 'toJSON' || property === 'toString' || property === 'toStringExtension' || property === 'didDefineProperty' || property === 'willWatchProperty' || property === 'didUnwatchProperty' || property === 'didAddListener' || property === 'didRemoveListener' || property === 'isDescriptor' || property === '_onLookup' || property in target) {
              return Reflect.get(target, property, receiver);
            }

            var value = target.unknownProperty.call(receiver, property);

            if (typeof value !== 'function') {
              (true && !(value === undefined || value === null) && (0, _debug.assert)(messageFor(receiver, property), value === undefined || value === null));
            }
          }
        });

        _container.FACTORY_FOR.set(self, initFactory);
      }

      var m = (0, _emberMeta.meta)(self);
      var proto = m.proto;
      m.proto = self;

      if (properties !== undefined) {
        (true && !(typeof properties === 'object' && properties !== null) && (0, _debug.assert)('EmberObject.create only accepts objects.', typeof properties === 'object' && properties !== null));
        (true && !(!(properties instanceof _emberMetal.Mixin)) && (0, _debug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin)));


        var concatenatedProperties = self.concatenatedProperties;
        var mergedProperties = self.mergedProperties;
        var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
        var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

        var keyNames = Object.keys(properties);

        for (var i = 0; i < keyNames.length; i++) {
          var keyName = keyNames[i];
          var value = properties[keyName];

          if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && _emberMetal.Mixin.detectBinding(keyName)) {
            m.writeBindings(keyName, value);
          }

          (true && !(!(value instanceof _emberMetal.ComputedProperty)) && (0, _debug.assert)('EmberObject.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty)));
          (true && !(!(typeof value === 'function' && value.toString().indexOf('._super') !== -1)) && (0, _debug.assert)('EmberObject.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1)));
          (true && !(!(keyName === 'actions' && _action_handler.default.detect(this))) && (0, _debug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this))));


          var possibleDesc = (0, _emberMeta.descriptorFor)(self, keyName, m);
          var isDescriptor = possibleDesc !== undefined;

          if (!isDescriptor) {
            var baseValue = self[keyName];

            if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
              if (baseValue) {
                value = (0, _emberUtils.makeArray)(baseValue).concat(value);
              } else {
                value = (0, _emberUtils.makeArray)(value);
              }
            }

            if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
              value = (0, _polyfills.assign)({}, baseValue, value);
            }
          }

          if (isDescriptor) {
            possibleDesc.set(self, keyName, value);
          } else if (typeof self.setUnknownProperty === 'function' && !(keyName in self)) {
            self.setUnknownProperty(keyName, value);
          } else {
            if (true) {
              (0, _emberMetal.defineProperty)(self, keyName, null, value, m); // setup mandatory setter
            } else {
              self[keyName] = value;
            }
          }
        }
      }

      if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
        _emberMetal.Mixin.finishPartial(self, m);
      }

      // using DEBUG here to avoid the extraneous variable when not needed
      if (true) {
        beforeInitCalled = false;
      }
      (_self = self).init.apply(_self, arguments);

      m.proto = proto;
      (0, _emberMetal.finishChains)(m);
      (0, _emberMetal.sendEvent)(self, 'init', undefined, undefined, undefined, m);

      // only return when in debug builds and `self` is the proxy created above
      if (true && self !== this) {
        return self;
      }
    }

    CoreObject.prototype.reopen = function reopen() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (0, _emberMetal.applyMixin)(this, args, true);
      return this;
    };

    CoreObject.prototype.init = function init() {};

    CoreObject.prototype.destroy = function destroy() {
      var m = (0, _emberMeta.peekMeta)(this);
      if (m.isSourceDestroying()) {
        return;
      }

      m.setSourceDestroying();

      (0, _runloop.schedule)('actions', this, this.willDestroy);
      (0, _runloop.schedule)('destroy', this, this._scheduledDestroy, m);

      return this;
    };

    CoreObject.prototype.willDestroy = function willDestroy() {};

    CoreObject.prototype._scheduledDestroy = function _scheduledDestroy(m) {
      if (m.isSourceDestroyed()) {
        return;
      }
      (0, _emberMeta.deleteMeta)(this);
      m.setSourceDestroyed();
    };

    CoreObject.prototype.toString = function toString() {
      var hasToStringExtension = typeof this.toStringExtension === 'function';
      var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

      var ret = '<' + ((0, _emberUtils.getName)(this) || _container.FACTORY_FOR.get(this) || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

      return ret;
    };

    CoreObject.extend = function extend() {
      var Class = function (_ref) {
        (0, _emberBabel.inherits)(Class, _ref);

        function Class() {
          (0, _emberBabel.classCallCheck)(this, Class);
          return (0, _emberBabel.possibleConstructorReturn)(this, _ref.apply(this, arguments));
        }

        return Class;
      }(this);
      _reopen.apply(Class.PrototypeMixin, arguments);
      return Class;
    };

    CoreObject.create = function create(props, extra) {
      var C = this;

      if (extra === undefined) {
        return new C(props);
      } else {
        return new C(flattenProps.apply(this, arguments));
      }
    };

    CoreObject.reopen = function reopen() {
      this.willReopen();
      _reopen.apply(this.PrototypeMixin, arguments);
      return this;
    };

    CoreObject.willReopen = function willReopen() {
      var p = this.prototype;
      if (wasApplied.has(p)) {
        wasApplied.delete(p);

        // If the base mixin already exists and was applied, create a new mixin to
        // make sure that it gets properly applied. Reusing the same mixin after
        // the first `proto` call will cause it to get skipped.
        if (prototypeMixinMap.has(this)) {
          prototypeMixinMap.set(this, _emberMetal.Mixin.create(this.PrototypeMixin));
        }
      }
    };

    CoreObject.reopenClass = function reopenClass() {
      (0, _emberMetal.applyMixin)(this, arguments, false);
      return this;
    };

    CoreObject.detect = function detect(obj) {
      if ('function' !== typeof obj) {
        return false;
      }
      while (obj) {
        if (obj === this) {
          return true;
        }
        obj = obj.superclass;
      }
      return false;
    };

    CoreObject.detectInstance = function detectInstance(obj) {
      return obj instanceof this;
    };

    CoreObject.metaForProperty = function metaForProperty(key) {
      var proto = this.proto(); // ensure prototype is initialized
      var possibleDesc = (0, _emberMeta.descriptorFor)(proto, key);

      (true && !(possibleDesc !== undefined) && (0, _debug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== undefined));


      return possibleDesc._meta || {};
    };

    CoreObject.eachComputedProperty = function eachComputedProperty(callback) {
      var binding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

      this.proto(); // ensure prototype is initialized
      var empty = {};

      (0, _emberMeta.meta)(this.prototype).forEachDescriptors(function (name, descriptor) {
        if (descriptor.enumerable) {
          var _meta = descriptor._meta || empty;
          callback.call(binding, name, _meta);
        }
      });
    };

    CoreObject.proto = function proto() {
      var p = this.prototype;
      if (!wasApplied.has(p)) {
        wasApplied.add(p);
        var parent = this.superclass;
        if (parent) {
          parent.proto();
        }

        // If the prototype mixin exists, apply it. In the case of native classes,
        // it will not exist (unless the class has been reopened).
        if (prototypeMixinMap.has(this)) {
          this.PrototypeMixin.apply(p);
        }
      }
      return p;
    };

    (0, _emberBabel.createClass)(CoreObject, [{
      key: 'isDestroyed',
      get: function () {
        return (0, _emberMeta.peekMeta)(this).isSourceDestroyed();
      },
      set: function (value) {
        (true && !(false) && (0, _debug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', false));
      }
    }, {
      key: 'isDestroying',
      get: function () {
        return (0, _emberMeta.peekMeta)(this).isSourceDestroying();
      },
      set: function (value) {
        (true && !(false) && (0, _debug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', false));
      }
    }], [{
      key: 'PrototypeMixin',
      get: function () {
        var prototypeMixin = prototypeMixinMap.get(this);
        if (prototypeMixin === undefined) {
          prototypeMixin = _emberMetal.Mixin.create();
          prototypeMixin.ownerConstructor = this;
          prototypeMixinMap.set(this, prototypeMixin);
        }
        return prototypeMixin;
      }
    }, {
      key: 'superclass',
      get: function () {
        var c = Object.getPrototypeOf(this);
        if (c !== Function.prototype) return c;
      }
    }]);
    return CoreObject;
  }();

  CoreObject.toString = _emberMetal.classToString;
  (0, _emberUtils.setName)(CoreObject, 'Ember.CoreObject');

  CoreObject.isClass = true;
  CoreObject.isMethod = false;

  function flattenProps() {
    var concatenatedProperties = this.concatenatedProperties,
        mergedProperties = this.mergedProperties;

    var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
    var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

    var initProperties = {};

    for (var _len2 = arguments.length, props = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      props[_key2] = arguments[_key2];
    }

    for (var i = 0; i < props.length; i++) {
      var properties = props[i];

      (true && !(!(properties instanceof _emberMetal.Mixin)) && (0, _debug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin)));


      var keyNames = Object.keys(properties);

      for (var j = 0, k = keyNames.length; j < k; j++) {
        var keyName = keyNames[j];
        var value = properties[keyName];

        if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
          var baseValue = initProperties[keyName];

          if (baseValue) {
            value = (0, _emberUtils.makeArray)(baseValue).concat(value);
          } else {
            value = (0, _emberUtils.makeArray)(value);
          }
        }

        if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
          var _baseValue = initProperties[keyName];

          value = (0, _polyfills.assign)({}, _baseValue, value);
        }

        initProperties[keyName] = value;
      }
    }

    return initProperties;
  }

  if (true) {
    /**
      Provides lookup-time type validation for injected properties.
       @private
      @method _onLookup
    */
    CoreObject._onLookup = function injectedPropertyAssertion(debugContainerKey) {
      var _debugContainerKey$sp = debugContainerKey.split(':'),
          type = _debugContainerKey$sp[0];

      var proto = this.proto();

      for (var key in proto) {
        var desc = (0, _emberMeta.descriptorFor)(proto, key);
        if (desc instanceof _emberMetal.InjectedProperty) {
          (true && !(type === 'controller' || desc.type !== 'controller') && (0, _debug.assert)('Defining `' + key + '` as an injected controller property on a non-controller (`' + debugContainerKey + '`) is not allowed.', type === 'controller' || desc.type !== 'controller'));
        }
      }
    };

    /**
      Returns a hash of property names and container names that injected
      properties will lookup on the container lazily.
       @method _lazyInjections
      @return {Object} Hash of all lazy injected property keys to container names
      @private
    */
    CoreObject._lazyInjections = function () {
      var injections = {};
      var proto = this.proto();
      var key = void 0;
      var desc = void 0;

      for (key in proto) {
        desc = (0, _emberMeta.descriptorFor)(proto, key);
        if (desc instanceof _emberMetal.InjectedProperty) {
          injections[key] = {
            namespace: desc.namespace,
            source: desc.source,
            specifier: desc.type + ':' + (desc.name || key)
          };
        }
      }

      return injections;
    };
  }

  exports.default = CoreObject;
});