enifed('ember-extension-support/index', ['exports', 'ember-extension-support/lib/data_adapter', 'ember-extension-support/lib/container_debug_adapter'], function (exports, _data_adapter, _container_debug_adapter) {
  'use strict';

  Object.defineProperty(exports, 'DataAdapter', {
    enumerable: true,
    get: function () {
      return _data_adapter.default;
    }
  });
  Object.defineProperty(exports, 'ContainerDebugAdapter', {
    enumerable: true,
    get: function () {
      return _container_debug_adapter.default;
    }
  });
});