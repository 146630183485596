define('ember-cli-file-saver/mixins/file-saver', ['exports', 'file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    saveCanvasAs: function saveCanvasAs(filename, canvas) {
      var _this = this;

      canvas.toBlob(function (blob) {
        return _this.saveAs(blob, filename);
      });
    },
    saveTextAs: function saveTextAs(filename, text) {
      this.saveFileAs(filename, text, 'text/plain;charset=utf-8');
    },
    saveFileAs: function saveFileAs(filename, content, contentType) {
      this.saveAs(new Blob([content], { type: contentType }), filename);
    },
    saveAs: function saveAs() {
      _fileSaver.default.saveAs.apply(_fileSaver.default, arguments);
    }
  });
});