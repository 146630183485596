define('ember-keyboard/mixins/activate-keyboard-on-focus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    activateKeyboardWhenFocused: Ember.on('click', 'focusIn', function () {
      Ember.set(this, 'keyboardActivated', true);
    }),

    deactivateKeyboardWhenFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardActivated', false);
    })
  });
});