define("ember-i18n/utils/add-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addTranslations;


  var assign = Ember.assign || Ember.merge;

  function addTranslations(locale, newTranslations, owner) {
    var key = "locale:" + locale + "/translations";
    var factory = owner.factoryFor(key);
    var existingTranslations = factory && factory.class;

    if (existingTranslations == null) {
      existingTranslations = {};
      owner.register(key, existingTranslations);
    }

    assign(existingTranslations, newTranslations);
  }
});