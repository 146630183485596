define('ember-keyboard/fixtures/code-maps/gecko', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    59: 'Semicolon',
    173: 'Minus',
    61: 'Equal',
    91: 'OSRight'
  };
});