define('ember-file-upload/computed/sum-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (collectionKey, itemKey) {
    return Ember.computed(collectionKey + '.@each.' + itemKey, function () {
      var collection = Ember.get(this, collectionKey);

      return collection.reduce(function (sum, item) {
        return sum + Ember.get(item, itemKey);
      }, 0);
    });
  };
});