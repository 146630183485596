define('ember-i18n/config/he', ['exports', 'ember-i18n/config/en'], function (exports, _en) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    rtl: true,

    pluralForm: _en.default.pluralForm
  };
});