define('ember-i18n/utils/get-locales', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getLocales;


  var matchKey = '/locales/(.+)/translations$'; /* globals require */
  function getLocales() {
    return Object.keys(require.entries).reduce(function (locales, module) {
      var match = module.match(matchKey);
      if (match) {
        locales.pushObject(match[1]);
      }
      return locales;
    }, Ember.A()).sort();
  }
});