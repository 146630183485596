define('ember-keyboard/fixtures/code-maps/gecko/linux', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    225: 'AltRight',
    6: 'Help',
    42: 'PrintScreen',
    108: 'NumpadDecimal'
  };
});