define('ember-ajax/-private/utils/is-string', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = isString;
    function isString(object) {
        return typeof object === 'string';
    }
});