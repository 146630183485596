define('ember-i18n/config/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ZERO = exports.ZERO = 'zero';
  var ONE = exports.ONE = 'one';
  var TWO = exports.TWO = 'two';
  var FEW = exports.FEW = 'few';
  var MANY = exports.MANY = 'many';
  var OTHER = exports.OTHER = 'other';
});