enifed('ember-testing/lib/initializers', ['@ember/application'], function (_application) {
  'use strict';

  var name = 'deferReadiness in `testing` mode';

  (0, _application.onLoad)('Ember.Application', function (Application) {
    if (!Application.initializers[name]) {
      Application.initializer({
        name: name,

        initialize: function (application) {
          if (application.testing) {
            application.deferReadiness();
          }
        }
      });
    }
  });
});