define("ember-i18n/config/en", ["exports", "ember-i18n/config/constants"], function (exports, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    rtl: false,

    pluralForm: function pluralForm(n) {
      if (n === 1) {
        return _constants.ONE;
      }
      return _constants.OTHER;
    }
  };
});