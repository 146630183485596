enifed('ember-views/lib/views/states/has_element', ['exports', '@ember/polyfills', 'ember-views/lib/views/states/default', '@ember/runloop', '@ember/instrumentation'], function (exports, _polyfills, _default2, _runloop, _instrumentation) {
  'use strict';

  var hasElement = Object.create(_default2.default);

  (0, _polyfills.assign)(hasElement, {
    rerender: function (view) {
      view.renderer.rerender(view);
    },
    destroy: function (view) {
      view.renderer.remove(view);
    },
    handleEvent: function (view, eventName, event) {
      if (view.has(eventName)) {
        // Handler should be able to re-dispatch events, so we don't
        // preventDefault or stopPropagation.
        return (0, _instrumentation.flaggedInstrument)('interaction.' + eventName, { event: event, view: view }, function () {
          return (0, _runloop.join)(view, view.trigger, eventName, event);
        });
      } else {
        return true; // continue event propagation
      }
    }
  });

  exports.default = hasElement;
});