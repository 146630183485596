define('ember-keyboard/mixins/activate-keyboard-on-insert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    activateKeyboardWhenPresent: Ember.on('didInsertElement', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });
});