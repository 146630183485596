enifed('@ember/application/lib/application', ['exports', 'ember-babel', 'ember-utils', 'ember-environment', 'ember-browser-environment', '@ember/debug', '@ember/runloop', 'ember-metal', '@ember/application/lib/lazy_load', 'ember-runtime', 'ember-views', 'ember-routing', '@ember/application/instance', '@ember/engine', 'container', 'ember-glimmer'], function (exports, _emberBabel, _emberUtils, _emberEnvironment, _emberBrowserEnvironment, _debug, _runloop, _emberMetal, _lazy_load, _emberRuntime, _emberViews, _emberRouting, _instance, _engine, _container, _emberGlimmer) {
  'use strict';

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['-bucket-cache:main'], ['-bucket-cache:main']);

  var librariesRegistered = false;

  /**
    An instance of `Application` is the starting point for every Ember
    application. It helps to instantiate, initialize and coordinate the many
    objects that make up your app.
  
    Each Ember app has one and only one `Application` object. In fact, the
    very first thing you should do in your application is create the instance:
  
    ```javascript
    import Application from '@ember/application';
  
    window.App = Application.create();
    ```
  
    Typically, the application object is the only global variable. All other
    classes in your app should be properties on the `Application` instance,
    which highlights its first role: a global namespace.
  
    For example, if you define a view class, it might look like this:
  
    ```javascript
    import Application from '@ember/application';
  
    App.MyView = Ember.View.extend();
    ```
  
    By default, calling `Application.create()` will automatically initialize
    your application by calling the `Application.initialize()` method. If
    you need to delay initialization, you can call your app's `deferReadiness()`
    method. When you are ready for your app to be initialized, call its
    `advanceReadiness()` method.
  
    You can define a `ready` method on the `Application` instance, which
    will be run by Ember when the application is initialized.
  
    Because `Application` inherits from `Ember.Namespace`, any classes
    you create will have useful string representations when calling `toString()`.
    See the `Ember.Namespace` documentation for more information.
  
    While you can think of your `Application` as a container that holds the
    other classes in your application, there are several other responsibilities
    going on under-the-hood that you may want to understand.
  
    ### Event Delegation
  
    Ember uses a technique called _event delegation_. This allows the framework
    to set up a global, shared event listener instead of requiring each view to
    do it manually. For example, instead of each view registering its own
    `mousedown` listener on its associated element, Ember sets up a `mousedown`
    listener on the `body`.
  
    If a `mousedown` event occurs, Ember will look at the target of the event and
    start walking up the DOM node tree, finding corresponding views and invoking
    their `mouseDown` method as it goes.
  
    `Application` has a number of default events that it listens for, as
    well as a mapping from lowercase events to camel-cased view method names. For
    example, the `keypress` event causes the `keyPress` method on the view to be
    called, the `dblclick` event causes `doubleClick` to be called, and so on.
  
    If there is a bubbling browser event that Ember does not listen for by
    default, you can specify custom events and their corresponding view method
    names by setting the application's `customEvents` property:
  
    ```javascript
    import Application from '@ember/application';
  
    let App = Application.create({
      customEvents: {
        // add support for the paste event
        paste: 'paste'
      }
    });
    ```
  
    To prevent Ember from setting up a listener for a default event,
    specify the event name with a `null` value in the `customEvents`
    property:
  
    ```javascript
    import Application from '@ember/application';
  
    let App = Application.create({
      customEvents: {
        // prevent listeners for mouseenter/mouseleave events
        mouseenter: null,
        mouseleave: null
      }
    });
    ```
  
    By default, the application sets up these event listeners on the document
    body. However, in cases where you are embedding an Ember application inside
    an existing page, you may want it to set up the listeners on an element
    inside the body.
  
    For example, if only events inside a DOM element with the ID of `ember-app`
    should be delegated, set your application's `rootElement` property:
  
    ```javascript
    import Application from '@ember/application';
  
    let App = Application.create({
      rootElement: '#ember-app'
    });
    ```
  
    The `rootElement` can be either a DOM element or a jQuery-compatible selector
    string. Note that *views appended to the DOM outside the root element will
    not receive events.* If you specify a custom root element, make sure you only
    append views inside it!
  
    To learn more about the events Ember components use, see
  
    [components/handling-events](https://guides.emberjs.com/release/components/handling-events/#toc_event-names).
  
    ### Initializers
  
    Libraries on top of Ember can add initializers, like so:
  
    ```javascript
    import Application from '@ember/application';
  
    Application.initializer({
      name: 'api-adapter',
  
      initialize: function(application) {
        application.register('api-adapter:main', ApiAdapter);
      }
    });
    ```
  
    Initializers provide an opportunity to access the internal registry, which
    organizes the different components of an Ember application. Additionally
    they provide a chance to access the instantiated application. Beyond
    being used for libraries, initializers are also a great way to organize
    dependency injection or setup in your own application.
  
    ### Routing
  
    In addition to creating your application's router, `Application` is
    also responsible for telling the router when to start routing. Transitions
    between routes can be logged with the `LOG_TRANSITIONS` flag, and more
    detailed intra-transition logging can be logged with
    the `LOG_TRANSITIONS_INTERNAL` flag:
  
    ```javascript
    import Application from '@ember/application';
  
    let App = Application.create({
      LOG_TRANSITIONS: true, // basic logging of successful transitions
      LOG_TRANSITIONS_INTERNAL: true // detailed logging of all routing steps
    });
    ```
  
    By default, the router will begin trying to translate the current URL into
    application state once the browser emits the `DOMContentReady` event. If you
    need to defer routing, you can call the application's `deferReadiness()`
    method. Once routing can begin, call the `advanceReadiness()` method.
  
    If there is any setup required before routing begins, you can implement a
    `ready()` method on your app that will be invoked immediately before routing
    begins.
  
    @class Application
    @extends Engine
    @uses RegistryProxyMixin
    @public
  */

  var Application = _engine.default.extend({
    /**
      The root DOM element of the Application. This can be specified as an
      element or a
      [jQuery-compatible selector string](http://api.jquery.com/category/selectors/).
       This is the element that will be passed to the Application's,
      `eventDispatcher`, which sets up the listeners for event delegation. Every
      view in your application should be a child of the element you specify here.
       @property rootElement
      @type DOMElement
      @default 'body'
      @public
    */
    rootElement: 'body',

    /**
      The `Ember.EventDispatcher` responsible for delegating events to this
      application's views.
       The event dispatcher is created by the application at initialization time
      and sets up event listeners on the DOM element described by the
      application's `rootElement` property.
       See the documentation for `Ember.EventDispatcher` for more information.
       @property eventDispatcher
      @type Ember.EventDispatcher
      @default null
      @public
    */
    eventDispatcher: null,

    /**
      The DOM events for which the event dispatcher should listen.
       By default, the application's `Ember.EventDispatcher` listens
      for a set of standard DOM events, such as `mousedown` and
      `keyup`, and delegates them to your application's `Ember.View`
      instances.
       If you would like additional bubbling events to be delegated to your
      views, set your `Application`'s `customEvents` property
      to a hash containing the DOM event name as the key and the
      corresponding view method name as the value. Setting an event to
      a value of `null` will prevent a default event listener from being
      added for that event.
       To add new events to be listened to:
       ```javascript
      import Application from '@ember/application';
       let App = Application.create({
        customEvents: {
          // add support for the paste event
          paste: 'paste'
        }
      });
      ```
       To prevent default events from being listened to:
       ```javascript
      import Application from '@ember/application';
       let App = Application.create({
        customEvents: {
          // remove support for mouseenter / mouseleave events
          mouseenter: null,
          mouseleave: null
        }
      });
      ```
      @property customEvents
      @type Object
      @default null
      @public
    */
    customEvents: null,

    /**
      Whether the application should automatically start routing and render
      templates to the `rootElement` on DOM ready. While default by true,
      other environments such as FastBoot or a testing harness can set this
      property to `false` and control the precise timing and behavior of the boot
      process.
       @property autoboot
      @type Boolean
      @default true
      @private
    */
    autoboot: true,

    /**
      Whether the application should be configured for the legacy "globals mode".
      Under this mode, the Application object serves as a global namespace for all
      classes.
       ```javascript
      import Application from '@ember/application';
      import Component from '@ember/component';
       let App = Application.create({
        ...
      });
       App.Router.reopen({
        location: 'none'
      });
       App.Router.map({
        ...
      });
       App.MyComponent = Component.extend({
        ...
      });
      ```
       This flag also exposes other internal APIs that assumes the existence of
      a special "default instance", like `App.__container__.lookup(...)`.
       This option is currently not configurable, its value is derived from
      the `autoboot` flag – disabling `autoboot` also implies opting-out of
      globals mode support, although they are ultimately orthogonal concerns.
       Some of the global modes features are already deprecated in 1.x. The
      existence of this flag is to untangle the globals mode code paths from
      the autoboot code paths, so that these legacy features can be reviewed
      for deprecation/removal separately.
       Forcing the (autoboot=true, _globalsMode=false) here and running the tests
      would reveal all the places where we are still relying on these legacy
      behavior internally (mostly just tests).
       @property _globalsMode
      @type Boolean
      @default true
      @private
    */
    _globalsMode: true,

    /**
      An array of application instances created by `buildInstance()`. Used
      internally to ensure that all instances get destroyed.
       @property _applicationInstances
      @type Array
      @default null
      @private
    */
    _applicationInstances: null,

    init: function () {
      // eslint-disable-line no-unused-vars
      this._super.apply(this, arguments);

      if (!this.$) {
        this.$ = _emberViews.jQuery;
      }

      registerLibraries();

      if (true) {
        if (_emberEnvironment.ENV.LOG_VERSION) {
          // we only need to see this once per Application#init
          _emberEnvironment.ENV.LOG_VERSION = false;
          _emberMetal.libraries.logVersions();
        }
      }

      // Start off the number of deferrals at 1. This will be decremented by
      // the Application's own `boot` method.
      this._readinessDeferrals = 1;
      this._booted = false;
      this._applicationInstances = new Set();

      this.autoboot = this._globalsMode = !!this.autoboot;

      if (this._globalsMode) {
        this._prepareForGlobalsMode();
      }

      if (this.autoboot) {
        this.waitForDOMReady();
      }
    },
    buildInstance: function () {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      options.base = this;
      options.application = this;
      return _instance.default.create(options);
    },
    _watchInstance: function (instance) {
      this._applicationInstances.add(instance);
    },
    _unwatchInstance: function (instance) {
      return this._applicationInstances.delete(instance);
    },
    _prepareForGlobalsMode: function () {
      // Create subclass of Router for this Application instance.
      // This is to ensure that someone reopening `App.Router` does not
      // tamper with the default `Router`.
      this.Router = (this.Router || _emberRouting.Router).extend();

      this._buildDeprecatedInstance();
    },
    _buildDeprecatedInstance: function () {
      // Build a default instance
      var instance = this.buildInstance();

      // Legacy support for App.__container__ and other global methods
      // on App that rely on a single, default instance.
      this.__deprecatedInstance__ = instance;
      this.__container__ = instance.__container__;
    },
    waitForDOMReady: function () {
      if (!this.$ || this.$.isReady) {
        (0, _runloop.schedule)('actions', this, 'domReady');
      } else {
        this.$().ready((0, _runloop.bind)(this, 'domReady'));
      }
    },
    domReady: function () {
      if (this.isDestroyed) {
        return;
      }

      this._bootSync();

      // Continues to `didBecomeReady`
    },
    deferReadiness: function () {
      (true && !(this instanceof Application) && (0, _debug.assert)('You must call deferReadiness on an instance of Application', this instanceof Application));
      (true && !(this._readinessDeferrals > 0) && (0, _debug.assert)('You cannot defer readiness since the `ready()` hook has already been called.', this._readinessDeferrals > 0));

      this._readinessDeferrals++;
    },
    advanceReadiness: function () {
      (true && !(this instanceof Application) && (0, _debug.assert)('You must call advanceReadiness on an instance of Application', this instanceof Application));

      this._readinessDeferrals--;

      if (this._readinessDeferrals === 0) {
        (0, _runloop.once)(this, this.didBecomeReady);
      }
    },
    boot: function () {
      if (this._bootPromise) {
        return this._bootPromise;
      }

      try {
        this._bootSync();
      } catch (_) {
        // Ignore the error: in the asynchronous boot path, the error is already reflected
        // in the promise rejection
      }

      return this._bootPromise;
    },
    _bootSync: function () {
      if (this._booted) {
        return;
      }

      // Even though this returns synchronously, we still need to make sure the
      // boot promise exists for book-keeping purposes: if anything went wrong in
      // the boot process, we need to store the error as a rejection on the boot
      // promise so that a future caller of `boot()` can tell what failed.
      var defer = this._bootResolver = _emberRuntime.RSVP.defer();
      this._bootPromise = defer.promise;

      try {
        this.runInitializers();
        (0, _lazy_load.runLoadHooks)('application', this);
        this.advanceReadiness();
        // Continues to `didBecomeReady`
      } catch (error) {
        // For the asynchronous boot path
        defer.reject(error);

        // For the synchronous boot path
        throw error;
      }
    },
    reset: function () {
      (true && !(this._globalsMode && this.autoboot) && (0, _debug.assert)('Calling reset() on instances of `Application` is not\n            supported when globals mode is disabled; call `visit()` to\n            create new `ApplicationInstance`s and dispose them\n            via their `destroy()` method instead.', this._globalsMode && this.autoboot));


      var instance = this.__deprecatedInstance__;

      this._readinessDeferrals = 1;
      this._bootPromise = null;
      this._bootResolver = null;
      this._booted = false;

      function handleReset() {
        (0, _runloop.run)(instance, 'destroy');
        this._buildDeprecatedInstance();
        (0, _runloop.schedule)('actions', this, '_bootSync');
      }

      (0, _runloop.join)(this, handleReset);
    },
    didBecomeReady: function () {
      try {
        // TODO: Is this still needed for _globalsMode = false?
        if (!(0, _debug.isTesting)()) {
          // Eagerly name all classes that are already loaded
          (0, _emberMetal.processAllNamespaces)();
          (0, _emberMetal.setNamespaceSearchDisabled)(true);
        }

        // See documentation on `_autoboot()` for details
        if (this.autoboot) {
          var instance = void 0;

          if (this._globalsMode) {
            // If we already have the __deprecatedInstance__ lying around, boot it to
            // avoid unnecessary work
            instance = this.__deprecatedInstance__;
          } else {
            // Otherwise, build an instance and boot it. This is currently unreachable,
            // because we forced _globalsMode to === autoboot; but having this branch
            // allows us to locally toggle that flag for weeding out legacy globals mode
            // dependencies independently
            instance = this.buildInstance();
          }

          instance._bootSync();

          // TODO: App.ready() is not called when autoboot is disabled, is this correct?
          this.ready();

          instance.startRouting();
        }

        // For the asynchronous boot path
        this._bootResolver.resolve(this);

        // For the synchronous boot path
        this._booted = true;
      } catch (error) {
        // For the asynchronous boot path
        this._bootResolver.reject(error);

        // For the synchronous boot path
        throw error;
      }
    },
    ready: function () {
      return this;
    },
    willDestroy: function () {
      this._super.apply(this, arguments);
      (0, _emberMetal.setNamespaceSearchDisabled)(false);
      this._booted = false;
      this._bootPromise = null;
      this._bootResolver = null;

      if (_lazy_load._loaded.application === this) {
        _lazy_load._loaded.application = undefined;
      }

      if (this._applicationInstances.size) {
        this._applicationInstances.forEach(function (i) {
          return i.destroy();
        });
        this._applicationInstances.clear();
      }
    },
    visit: function (url, options) {
      var _this = this;

      return this.boot().then(function () {
        var instance = _this.buildInstance();

        return instance.boot(options).then(function () {
          return instance.visit(url);
        }).catch(function (error) {
          (0, _runloop.run)(instance, 'destroy');
          throw error;
        });
      });
    }
  });

  Application.reopenClass({
    buildRegistry: function () {
      // eslint-disable-line no-unused-vars
      var registry = this._super.apply(this, arguments);

      commonSetupRegistry(registry);

      (0, _emberGlimmer.setupApplicationRegistry)(registry);

      return registry;
    }
  });

  function commonSetupRegistry(registry) {
    registry.register('router:main', _emberRouting.Router.extend());
    registry.register('-view-registry:main', {
      create: function () {
        return (0, _emberUtils.dictionary)(null);
      }
    });

    registry.register('route:basic', _emberRouting.Route);
    registry.register('event_dispatcher:main', _emberViews.EventDispatcher);

    registry.injection('router:main', 'namespace', 'application:main');

    registry.register('location:auto', _emberRouting.AutoLocation);
    registry.register('location:hash', _emberRouting.HashLocation);
    registry.register('location:history', _emberRouting.HistoryLocation);
    registry.register('location:none', _emberRouting.NoneLocation);

    registry.register((0, _container.privatize)(_templateObject), {
      create: function () {
        return new _emberRouting.BucketCache();
      }
    });

    if (true) {
      registry.register('service:router', _emberRouting.RouterService);
      registry.injection('service:router', '_router', 'router:main');
    }
  }

  function registerLibraries() {
    if (!librariesRegistered) {
      librariesRegistered = true;

      if (_emberBrowserEnvironment.hasDOM && !_emberViews.jQueryDisabled) {
        _emberMetal.libraries.registerCoreLibrary('jQuery', (0, _emberViews.jQuery)().jquery);
      }
    }
  }

  exports.default = Application;
});