define('ember-keyboard/fixtures/code-maps/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    48: 'Digit0',
    49: 'Digit1',
    50: 'Digit2',
    51: 'Digit3',
    52: 'Digit4',
    53: 'Digit5',
    54: 'Digit6',
    55: 'Digit7',
    56: 'Digit8',
    57: 'Digit9',
    65: 'KeyA',
    66: 'KeyB',
    67: 'KeyC',
    68: 'KeyD',
    69: 'KeyE',
    70: 'KeyF',
    71: 'KeyG',
    72: 'KeyH',
    73: 'KeyI',
    74: 'KeyJ',
    75: 'KeyK',
    76: 'KeyL',
    77: 'KeyM',
    78: 'KeyN',
    79: 'KeyO',
    80: 'KeyP',
    81: 'KeyQ',
    82: 'KeyR',
    83: 'KeyS',
    84: 'KeyT',
    85: 'KeyU',
    86: 'KeyV',
    87: 'KeyW',
    88: 'KeyX',
    89: 'KeyY',
    90: 'KeyZ',
    188: 'Comma',
    190: 'Period',
    186: 'Semicolon',
    191: 'Slash',
    222: 'Quote',
    219: 'BracketLeft',
    221: 'BracketRight',
    192: 'Backquote',
    220: 'Backslash',
    189: 'Minus',
    187: 'Equal',
    18: 'AltLeft',
    20: 'CapsLock',
    17: 'ControlLeft',
    91: 'OSLeft',
    92: 'OSRight',
    16: 'ShiftLeft',
    93: 'ContextMenu',
    13: 'Enter',
    32: 'Space',
    9: 'Tab',
    8: 'Backspace',
    46: 'Delete',
    35: 'End',
    36: 'Home',
    45: 'Insert',
    34: 'PageDown',
    33: 'PageUp',
    40: 'ArrowDown',
    37: 'ArrowLeft',
    39: 'ArrowRight',
    38: 'ArrowUp',
    27: 'Escape',
    44: 'PrintScreen',
    145: 'ScrollLock',
    19: 'Pause',
    112: 'F1',
    113: 'F2',
    114: 'F3',
    115: 'F4',
    116: 'F5',
    117: 'F6',
    118: 'F7',
    119: 'F8',
    120: 'F9',
    121: 'F10',
    122: 'F11',
    123: 'F12',
    124: 'F13',
    125: 'F14',
    126: 'F15',
    127: 'F16',
    128: 'F17',
    129: 'F18',
    130: 'F19',
    131: 'F20',
    132: 'F21',
    133: 'F22',
    134: 'F23',
    135: 'F24',
    144: 'NumLock',
    96: 'Numpad0',
    97: 'Numpad1',
    98: 'Numpad2',
    99: 'Numpad3',
    100: 'Numpad4',
    101: 'Numpad5',
    102: 'Numpad6',
    103: 'Numpad7',
    104: 'Numpad8',
    105: 'Numpad9',
    107: 'NumpadAdd',
    194: 'NumpadComma',
    110: 'NumpadDecimal',
    111: 'NumpadDivide',
    12: 'NumpadEqual',
    106: 'NumpadMultiply',
    109: 'NumpadSubtract'
  };
});