define('ember-i18n/initializers/ember-i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-i18n',
    initialize: function initialize() {
      // No-op.
    }
  };
});