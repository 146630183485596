define('ember-keyboard/fixtures/code-maps/gecko/mac', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    224: 'OSLeft',
    12: 'NumLock',
    108: 'NumpadComma'
  };
});