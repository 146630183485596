define("ember-i18n/utils/i18n/compile-template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = compileTemplate;


  var htmlSafe = Ember.String.htmlSafe;
  var get = Ember.get;
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  var tripleStache = /\{\{\{\s*(.*?)\s*\}\}\}/g;
  var doubleStache = /\{\{\s*(.*?)\s*\}\}/g;

  // @public
  //
  // Compile a translation template.
  //
  // To override this, define `util:i18n/compile-template` with
  // the signature
  // `Function(String, Boolean) -> Function(Object) -> String`.
  function compileTemplate(template) {
    var rtl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    return function renderTemplate(data) {
      var result = template.replace(tripleStache, function (i, match) {
        return get(data, match);
      }).replace(doubleStache, function (i, match) {
        return escapeExpression(get(data, match));
      });

      var wrapped = rtl ? "\u202B" + result + "\u202C" : result;

      return htmlSafe(wrapped);
    };
  }
});