enifed('ember-browser-environment', ['exports'], function (exports) {
    'use strict';

    // check if window exists and actually is the global
    var hasDom = typeof self === 'object' && self !== null && self.Object === Object && typeof Window !== 'undefined' && self.constructor === Window && typeof document === 'object' && document !== null && self.document === document && typeof location === 'object' && location !== null && self.location === location && typeof history === 'object' && history !== null && self.history === history && typeof navigator === 'object' && navigator !== null && self.navigator === navigator && typeof navigator.userAgent === 'string';

    var window = hasDom ? self : null;
    var location$1 = hasDom ? self.location : null;
    var history$1 = hasDom ? self.history : null;
    var userAgent = hasDom ? self.navigator.userAgent : 'Lynx (textmode)';
    var isChrome = hasDom ? !!window.chrome && !window.opera : false;
    var isFirefox = hasDom ? typeof InstallTrigger !== 'undefined' : false;

    exports.window = window;
    exports.location = location$1;
    exports.history = history$1;
    exports.userAgent = userAgent;
    exports.isChrome = isChrome;
    exports.isFirefox = isFirefox;
    exports.hasDOM = hasDom;
});