enifed('ember-meta/index', ['exports', 'ember-meta/lib/meta'], function (exports, _meta) {
  'use strict';

  Object.defineProperty(exports, 'counters', {
    enumerable: true,
    get: function () {
      return _meta.counters;
    }
  });
  Object.defineProperty(exports, 'deleteMeta', {
    enumerable: true,
    get: function () {
      return _meta.deleteMeta;
    }
  });
  Object.defineProperty(exports, 'descriptorFor', {
    enumerable: true,
    get: function () {
      return _meta.descriptorFor;
    }
  });
  Object.defineProperty(exports, 'isDescriptor', {
    enumerable: true,
    get: function () {
      return _meta.isDescriptor;
    }
  });
  Object.defineProperty(exports, 'Meta', {
    enumerable: true,
    get: function () {
      return _meta.Meta;
    }
  });
  Object.defineProperty(exports, 'meta', {
    enumerable: true,
    get: function () {
      return _meta.meta;
    }
  });
  Object.defineProperty(exports, 'peekMeta', {
    enumerable: true,
    get: function () {
      return _meta.peekMeta;
    }
  });
  Object.defineProperty(exports, 'setMeta', {
    enumerable: true,
    get: function () {
      return _meta.setMeta;
    }
  });
  Object.defineProperty(exports, 'UNDEFINED', {
    enumerable: true,
    get: function () {
      return _meta.UNDEFINED;
    }
  });
});