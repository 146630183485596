enifed("@ember/debug/lib/testing", ["exports"], function (exports) {
    "use strict";

    exports.isTesting = isTesting;
    exports.setTesting = setTesting;
    var testing = false;
    function isTesting() {
        return testing;
    }
    function setTesting(value) {
        testing = !!value;
    }
});