enifed('ember-routing/lib/services/router', ['exports', '@ember/service', '@ember/object/computed', 'ember-routing/lib/utils'], function (exports, _service, _computed, _utils) {
  'use strict';

  /**
     The Router service is the public API that provides access to the router.
  
     The immediate benefit of the Router service is that you can inject it into components, 
     giving them a friendly way to initiate transitions and ask questions about the current 
     global router state.
  
     In this example, the Router service is injected into a component to initiate a transition 
     to a dedicated route:
     ```javascript
     import Component from '@ember/component';
     import { inject as service } from '@ember/service';
  
     export default Component.extend({
       router: service(),
  
       actions: {
         next() {
           this.get('router').transitionTo('other.route');
         }
       }
     });
     ```
  
     Like any service, it can also be injected into helpers, routes, etc.
  
     @public
     @class RouterService
     @category ember-routing-router-service
   */
  var RouterService = _service.default.extend({
    /**
       Name of the current route.
        This property represent the logical name of the route,
       which is comma separated.
       For the following router:
        ```app/router.js
       Router.map(function() {
         this.route('about');
         this.route('blog', function () {
           this.route('post', { path: ':post_id' });
         });
       });
       ```
        It will return:
        * `index` when you visit `/`
       * `about` when you visit `/about`
       * `blog.index` when you visit `/blog`
       * `blog.post` when you visit `/blog/some-post-id`
        @property currentRouteName
       @type String
       @public
     */
    currentRouteName: (0, _computed.readOnly)('_router.currentRouteName'),

    /**
       Current URL for the application.
       This property represent the URL path for this route.
      For the following router:
        ```app/router.js
       Router.map(function() {
         this.route('about');
         this.route('blog', function () {
           this.route('post', { path: ':post_id' });
         });
       });
       ```
        It will return:
        * `/` when you visit `/`
       * `/about` when you visit `/about`
       * `/blog` when you visit `/blog`
       * `/blog/some-post-id` when you visit `/blog/some-post-id`
        @property currentURL
       @type String
       @public
     */
    currentURL: (0, _computed.readOnly)('_router.currentURL'),

    /**
      The `location` property determines the type of URL's that your
      application will use.
      The following location types are currently available:
      * `auto`
      * `hash`
      * `history`
      * `none`
       @property location
      @default 'hash'
      @see {Location}
      @public
    */
    location: (0, _computed.readOnly)('_router.location'),

    /**
      The `rootURL` property represents the URL of the root of
      the application, '/' by default.
      This prefix is assumed on all routes defined on this app.
       IF you change the `rootURL` in your environment configuration
      like so:
       ```config/environment.js
      'use strict';
       module.exports = function(environment) {
        let ENV = {
          modulePrefix: 'router-service',
          environment,
          rootURL: '/my-root',
        …
        }
      ]
      ```
       This property will return `/my-root`.
       @property rootURL
      @default '/'
      @public
    */
    rootURL: (0, _computed.readOnly)('_router.rootURL'),
    _router: null,

    transitionTo: function () {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      if ((0, _utils.resemblesURL)(args[0])) {
        return this._router._doURLTransition('transitionTo', args[0]);
      }

      var _extractRouteArgs = (0, _utils.extractRouteArgs)(args),
          routeName = _extractRouteArgs.routeName,
          models = _extractRouteArgs.models,
          queryParams = _extractRouteArgs.queryParams;

      var transition = this._router._doTransition(routeName, models, queryParams, true);
      transition._keepDefaultQueryParamValues = true;

      return transition;
    },
    replaceWith: function () /* routeNameOrUrl, ...models, options */{
      return this.transitionTo.apply(this, arguments).method('replace');
    },
    urlFor: function () /* routeName, ...models, options */{
      var _router;

      return (_router = this._router).generate.apply(_router, arguments);
    },
    isActive: function () {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var _extractRouteArgs2 = (0, _utils.extractRouteArgs)(args),
          routeName = _extractRouteArgs2.routeName,
          models = _extractRouteArgs2.models,
          queryParams = _extractRouteArgs2.queryParams;

      var routerMicrolib = this._router._routerMicrolib;

      if (!routerMicrolib.isActiveIntent(routeName, models, null)) {
        return false;
      }
      var hasQueryParams = Object.keys(queryParams).length > 0;

      if (hasQueryParams) {
        this._router._prepareQueryParams(routeName, models, queryParams, true /* fromRouterService */
        );
        return (0, _utils.shallowEqual)(queryParams, routerMicrolib.state.queryParams);
      }

      return true;
    }
  });

  exports.default = RouterService;
});