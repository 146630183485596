enifed('ember-runtime/lib/system/object', ['exports', 'ember-babel', 'container', 'ember-owner', 'ember-utils', 'ember-metal', 'ember-runtime/lib/system/core_object', 'ember-runtime/lib/mixins/observable', '@ember/debug'], function (exports, _emberBabel, _container, _emberOwner, _emberUtils, _emberMetal, _core_object, _observable, _debug) {
  'use strict';

  exports.FrameworkObject = undefined;


  var OVERRIDE_OWNER = (0, _emberUtils.symbol)('OVERRIDE_OWNER');

  /**
    `EmberObject` is the main base class for all Ember objects. It is a subclass
    of `CoreObject` with the `Observable` mixin applied. For details,
    see the documentation for each of these.
  
    @class EmberObject
    @extends CoreObject
    @uses Observable
    @public
  */
  /**
  @module @ember/object
  */

  var EmberObject = function (_CoreObject) {
    (0, _emberBabel.inherits)(EmberObject, _CoreObject);

    function EmberObject() {
      (0, _emberBabel.classCallCheck)(this, EmberObject);
      return (0, _emberBabel.possibleConstructorReturn)(this, _CoreObject.apply(this, arguments));
    }

    (0, _emberBabel.createClass)(EmberObject, [{
      key: '_debugContainerKey',
      get: function () {
        var factory = _container.FACTORY_FOR.get(this);
        return factory !== undefined && factory.fullName;
      }
    }, {
      key: _emberOwner.OWNER,
      get: function () {
        if (this[OVERRIDE_OWNER]) {
          return this[OVERRIDE_OWNER];
        }

        var factory = _container.FACTORY_FOR.get(this);
        return factory !== undefined && factory.owner;
      },
      set: function (value) {
        this[OVERRIDE_OWNER] = value;
      }
    }]);
    return EmberObject;
  }(_core_object.default);

  exports.default = EmberObject;


  (0, _emberUtils.setName)(EmberObject, 'Ember.Object');

  _observable.default.apply(EmberObject.prototype);

  var FrameworkObject = exports.FrameworkObject = EmberObject;

  if (true) {
    var INIT_WAS_CALLED = (0, _emberUtils.symbol)('INIT_WAS_CALLED');
    var ASSERT_INIT_WAS_CALLED = (0, _emberUtils.symbol)('ASSERT_INIT_WAS_CALLED');

    exports.FrameworkObject = FrameworkObject = function (_EmberObject) {
      (0, _emberBabel.inherits)(FrameworkObject, _EmberObject);

      function FrameworkObject() {
        (0, _emberBabel.classCallCheck)(this, FrameworkObject);
        return (0, _emberBabel.possibleConstructorReturn)(this, _EmberObject.apply(this, arguments));
      }

      FrameworkObject.prototype.init = function init() {
        var _EmberObject$prototyp;

        (_EmberObject$prototyp = _EmberObject.prototype.init).call.apply(_EmberObject$prototyp, [this].concat(Array.prototype.slice.call(arguments)));
        this[INIT_WAS_CALLED] = true;
      };

      FrameworkObject.prototype[ASSERT_INIT_WAS_CALLED] = function () {
        (true && !(this[INIT_WAS_CALLED]) && (0, _debug.assert)('You must call `this._super(...arguments);` when overriding `init` on a framework object. Please update ' + this + ' to call `this._super(...arguments);` from `init`.', this[INIT_WAS_CALLED]));
      };

      return FrameworkObject;
    }(EmberObject);

    (0, _emberMetal.addListener)(FrameworkObject.prototype, 'init', null, ASSERT_INIT_WAS_CALLED);
  }
});